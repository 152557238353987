.App {
  text-align: center;
}

header {
  padding: 1rem;
  text-align: left;
  margin-bottom: -74.75px;
}

header img {
  width: 40px;
}

.App div {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

#logoText {
  width: 400px;
  margin-bottom: -20px;
}

#motto {
  font-size: 30px;
}

p {
  color: #FFFFFF;
  margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {
  #logoText {
    width: 80%;
  }
}
